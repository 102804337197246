import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';

export interface InputProps extends Omit<React.HtmlHTMLAttributes<HTMLInputElement>, 'onChange'> {
  type?: 'text' | 'number' | 'email' | 'password';
  value?: string | number;
  label?: React.ReactNode;
  extra?: React.ReactNode;
  min?: number;
  max?: number;
  step?: number;
  minLength?: number;
  maxLength?: number;
  disabled?: boolean;
  onClick?: () => void;
  onChange?: (value: string | number) => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      value = '',
      label,
      extra,
      min,
      max,
      step,
      minLength,
      maxLength,
      placeholder,
      disabled = false,
      className,
      onClick,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [state, setState] = useState(value);

    useIsomorphicLayoutEffect(() => setState(value), [value]);

    const onChangeHandler = useCallback(
      (e) => {
        let val = e.target.value.toString().slice(0, maxLength || e.target.value.length);

        if (type === 'number' && max && parseInt(val) >= max) {
          val = max;
        }

        if (onChange) {
          onChange(val);
        }
        type !== 'number' && setState(val);
      },
      [onChange],
    );

    return (
      <>
        {label && <p className="input__label">{label}</p>}
        <label className={cn('input__container', { 'is-disabled': disabled }, className)}>
          <input
            ref={ref}
            type={type}
            value={state}
            min={min}
            max={max}
            step={step}
            minLength={minLength}
            maxLength={maxLength}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChangeHandler}
            {...props}
          />
          {extra}
        </label>
      </>
    );
  },
);

export default Input;
